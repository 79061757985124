
import {
  defineComponent,
  reactive,
  ref,
  watch,
  onMounted,
  unref,
  ComponentOptionsBase,
  ComponentPublicInstance,
  Ref,
  computed,
} from "vue";
import { register, loginApi, getGoogleUrl, googleCallback } from "@/api/login";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import tools from "@/utils/tools";
import { ElMessage, LoadingParentElement, ElLoading } from "element-plus";
import type { FormInstance } from "element-plus";
import languageChange from "@/components/whatsapp/languageChange.vue";
import loadingChat from "@/components/chat/loading.vue";
import { useI18n } from "vue-i18n";
import { gaEvent } from "@/utils/format";
import { progressbarWs } from "@/api/login";
import Cookies from 'js-cookie'
import { config } from "@/utils/config";

export default defineComponent({
  name: "signup",
  components: {
    languageChange,
    loadingChat,
  },
  setup(prop, context) {
    let router = useRouter();
    const store = useStore();
    const i18n = useI18n();
    const ruleFormss = ref();
    const senMsg = 1;
    const originUrl = ref(1)
    // 标识当前是移动端还是pc端
    const equipmentType = computed(() => store.state.equipmentType)
    const currentLang = computed(() => store.state.currentLang)
    const formData = reactive({
      UserEmail: "",

      isOk: false,

      isAgree: true, // 保密协议是否勾选
      isAdult: true, // 是否成年

      inputEmail: false,
      // btnText: "Sign up",
      isSignUp: true,
      disable: false,
      isSendLoading: false,
      isGoogleSign: false,
      googleRedirect: false,
    });
    onMounted(() => {
      if (window.location.origin.includes('famikeep')) {
        originUrl.value = 0
      }
      setTimeout(() => {
        const timing = window.performance.timing;
        const loadTime = Math.floor(timing.loadEventEnd - timing.navigationStart);
        console.log(`Window loaded: ${window.performance.now()} milliseconds`);
        console.log(`Window loaded: ${loadTime} milliseconds`);
        gaEvent("SignUp_LoadingTime", {
          event_category: "LoadingTime",
          event_label: `${loadTime}ms`,
        });
      }, 2000);


      if (window.location.href.includes('code')) {
        // 提取 code 和 state 的值
        const codeMatch = window.location.href.match(/code=([^&]+)/);
        const stateMatch = window.location.href.match(/state=([^&]+)/);

        // 获取匹配的值
        const code = codeMatch ? codeMatch[1] : null;
        const state = stateMatch ? stateMatch[1] : null;

        // console.log('Code:', code);
        // console.log('State:', state);
        formData.isGoogleSign = true
        formData.googleRedirect = true
        callBackLogin(code, state)
      }
    })
    watch(
      () => [formData.UserEmail],
      (newValue, oldVaule) => {
        if (formData.UserEmail) {
          formData.isOk = true;
        } else {
          formData.isOk = false;
        }
      }
    );

    let homePage = () => {
      if (window.location.origin.includes('parental')) {
        window.open("https://www.parentalguard.com/", "_blank");
      } else {
        window.open("https://www.famikeep.com/", "_blank");
      }
    };

    const emailRule = computed(() => {
      const rule = reactive({
        UserEmail: [
          { validator: validateUserNameASCII, trigger: "change" },
          {
            required: true,
            message: i18n.t("pleaseEnterEmailAddress"),
            trigger: "blur",
          },
          {
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: i18n.t("invalidEmailFormat"),
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^\S{1,100}$/,
            message: i18n.t("invalidEmailFormat"),
            trigger: "blur",
          },
        ],
      });
      return rule;
    });

    const validateUserNameASCII = (
      _rule: any,
      value: string,
      callback: () => void
    ) => {
      var re = /[^a-zA-Z0-9!"#$%&'()*+,.\\\/:;<=>?@\[\] ^_`{|}~-]/g;
      formData.UserEmail = value.replace(re, "");
      callback();
    };

    // 验证
    let UserEmailTrim = () => {
      formData.UserEmail = tools.LTrim(tools.RTrim(formData.UserEmail));
    };

    const registerFormRef = ref<FormInstance>();
    // const formRef: any = unref(registerFormRef);
    let checkEmail = () => {
      return new Promise((res, rej) => {
        // unref 如果参数是一个 ref 则返回它的 value，否则返回参数本身。
        const formRef: any = unref(registerFormRef);
        formRef.validateField("UserEmail", (valid: boolean) => {
          if (valid) {
            formData.inputEmail = true;
            res(true);
          } else {
            formData.inputEmail = false;
            res(false);
          }
        });
      })
    };

    const refreshTab = () => {
      store.dispatch("getUserInfo");
      store.dispatch("getDeviceInfo");
      store.dispatch("updateMenuList");
    };

    let setRegister = async () => {
      if (!formData.UserEmail) return
      if (!registerFormRef.value) return
      await checkEmail()
      if (
        !(formData.inputEmail)
      ) {
        return;
      }

      if (!formData.isOk) {
        return;
      }
      if (!formData.isAgree && !formData.isAdult) {
        // duration 自动关闭时间
        ElMessage({
          message: i18n.t("pleaseReadAndAgree"),
          type: "error",
        });
        return;
      }
      let setLang = currentLang.value
      if (setLang == 'ja') setLang = 'jp'
      else if (setLang == 'ms') setLang = 'my'
      else if (setLang == 'ko') setLang = 'kr'
      else if (setLang == 'pt') setLang = 'br'
      else if (setLang == 'zh-CN') setLang = 'cn'
      else if (setLang == 'zh-TW') setLang = 'tw'
      let data = {
        email: formData.UserEmail,
        lang: setLang
      };

      if (formData.disable === false) {
        formData.disable = true;
        // formData.btnText = "Creating  Account…";
        // 点击注册按钮后统计一次
        tools.UserEvent("Register_Button", "None");
        let regstartTime = Date.now();
        formData.isSignUp = false;
        register(data)
          .then((res) => {
            // console.log("注册成功", res);
            if (res.data.code == 200) {
              sessionStorage.setItem("resetTempEmail", formData.UserEmail);
              tools.UserEvent("Register_Success", "Normal");
              let regTimes = Math.round(Date.now() - regstartTime);
              gaEvent("SignUpResult_LoadingTime", {
                event_category: "LoadingTime",
                event_label: `${regTimes}ms`,
              });
              let loginstartTime = Date.now();
              let params = {
                email: formData.UserEmail,
                password: res.data.data.temporary_password
              };
              loginApi(params)
                .then((res) => {
                  if (res.data.code == "200") {
                    // router.push({ path: "/" });
                    localStorage.setItem("token", res.data.data.token);
                    // 登录密钥
                    localStorage.setItem("authorization", res.data.data.token);
                    console.log("res.data.authorization:", res.data.data.token);
                    Cookies.set('isLogin', 'true', { expires: 30 });

                    // Cookies.set('authorization', res.data.data.token, { expires: 30 });
                    // store.commit('SET_Authorization', res.data.data.token);
                    localStorage.setItem(
                      "userInfo",
                      JSON.stringify(res.data.data)
                    );
                    localStorage.setItem("user_id", res.data.data.user_id);
                    let loginTimes = Math.round(Date.now() - loginstartTime);
                    gaEvent("SignInResult_LoadingTime", {
                      event_category: "LoadingTime",
                      event_label: `${loginTimes}ms`,
                    });
                    tools.UserEvent("Login_Success", "Normal");
                    // start
                    store.dispatch("getDeviceInfo").then(async () => {
                      if (store.state.deviceInfo.length > 0) {
                        if (store.state.deviceInfo[0].id) {
                          store.commit(
                            "setCurrentDeviceId",
                            store.state.deviceInfo[0].id
                          );
                          store.commit("setCurrentDeviceInfo", store.state.deviceInfo[0]);
                          store.commit('setDeviceType', 0);
                        } else {
                          store.commit('setCurrentAppleId', store.state.deviceInfo[0].apple_id)
                          store.commit(
                            "setCurrentDeviceId",
                            store.state.deviceInfo[0].devices[0].id
                          );
                          store.commit("setCurrentDeviceInfo", { ...store.state.deviceInfo[0].devices[0], is_expired: store.state.deviceInfo[0].is_expired, expire_at: store.state.deviceInfo[0].expired_at });
                          store.commit('setDeviceType', 1);
                        }
                      }

                      await store.dispatch("getUserInfo");

                      if (!store.state.subscripts.expires_at) {
                        store.commit("setDefaultMenu", null);
                        gaEvent("Buy_Page", {
                          event_category: "Buy",
                          event_label: "From_Login",
                        });
                        router.push({ path: "/addDevice" });
                      } else if (
                        !store.state.deviceInfo.length &&
                        store.state.subscripts.expires_at
                      ) {
                        store.commit("setDefaultMenu", null);
                        router.push({ path: "/guide" });
                        store.commit("setCurrentDeviceId", "Device 1");
                        store.commit("setCurrentDeviceInfo", {
                          name: "leftTab.unboundName",
                          id: "Device 1",
                          model: "leftTab.unbound",
                        });
                        store.commit("setBoundImg", true);
                      } else {
                        router.push({ path: "/home" });
                        store.commit("setDefaultMenu", "home");
                      }

                      // // 调取设备信息
                      store.dispatch("updateMenuList");
                    });

                    ElMessage.success(i18n.t("loginSucceeded"));

                    let code = localStorage.getItem("authorization");
                    if (localStorage.getItem("authorization")) {
                      progressbarWs(code, 1);
                    }
                    if (localStorage.getItem("oos") == "1") {
                      refreshTab();
                      localStorage.setItem("oos", "3");
                    } else if (localStorage.getItem("oos") == "2") {
                      refreshTab();
                      localStorage.setItem("oos", "3");
                    }
                  } else {
                    tools.UserEvent("Login_Fail", res.data.code);
                    tools.responseUse(res.data.code);
                    let loginTimes = Math.round(Date.now() - loginstartTime);
                    gaEvent("SignInResult_LoadingTime", {
                      event_category: "LoadingTime",
                      event_label: `${loginTimes}ms`,
                    });
                  }
                })
                .catch((err) => {
                  console.log("登录失败", err);
                  tools.UserEvent("Login_Fail", "None");
                  formData.disable = false;
                  // formData.btnText = "Sign up";
                  formData.isSignUp = true;
                  let loginTimes = Math.round(Date.now() - loginstartTime);
                  gaEvent("SignInResult_LoadingTime", {
                    event_category: "LoadingTime",
                    event_label: `${loginTimes}ms`,
                  });
                });
            } else {
              tools.UserEvent("Register_Fail", res.data.code);
              tools.responseUse(res.data.code);
              // ElMessage.error(res.data.message);
              formData.disable = false;
              formData.isSignUp = true;
              let regTimes = Math.round(Date.now() - regstartTime);
              gaEvent("SignUpResult_LoadingTime", {
                event_category: "LoadingTime",
                event_label: `${regTimes}ms`,
              });
            }
          })
          .catch((err) => {
            tools.UserEvent("Register_Fail", "None");
            console.log("注册失败", err);
            formData.disable = false;
            formData.isSignUp = true;
            let regTimes = Math.round(Date.now() - regstartTime);
            gaEvent("SignUpResult_LoadingTime", {
              event_category: "LoadingTime",
              event_label: `${regTimes}ms`,
            });
          });
      }
    };

    // 谷歌登录
    const goGoole = () => {
      if (!formData.isSignUp) return;
      formData.isGoogleSign = true
      gaEvent("GoogleRegister_Button", {
        event_category: "LogRegister",
        event_label: 'None',
      });
      let params = {
        target: 0,
      }
      getGoogleUrl(params).then((res) => {
        let callbackUrl = res.data.data.url
        window.open(callbackUrl, '_self')
        setTimeout(() => {
          formData.isGoogleSign = false
        }, 1000)
      }).catch((err) => {
        console.log('err', err);

      })
    }

    const callBackLogin = async (code: any, state: any) => {
      let loginstartTime = Date.now();
      let setLang = currentLang.value
      if (setLang == 'ja') setLang = 'jp'
      else if (setLang == 'ms') setLang = 'my'
      else if (setLang == 'ko') setLang = 'kr'
      else if (setLang == 'pt') setLang = 'br'
      else if (setLang == 'zh-CN') setLang = 'cn'
      else if (setLang == 'zh-TW') setLang = 'tw'
      let params = {
        code: code,
        state: state,
        lang: setLang,
      }
      googleCallback(params).then((res) => {
        console.log('codecode', res.data);
        if (res.data.code == "200") {
          console.log('scuess');
          gaEvent("Register_Success", {
            event_category: "LogRegister",
            event_label: 'Google',
          });
          gaEvent("Login_Success", {
            event_category: "LogRegister",
            event_label: 'Google',
          });
          // router.push({ path: "/" });
          localStorage.setItem("token", res.data.data.token);
          // 登录密钥
          localStorage.setItem("authorization", res.data.data.token);
          console.log("res.data.authorization:", res.data.data.token);
          Cookies.set('isLogin', 'true', { expires: 30 });

          // Cookies.set('authorization', res.data.data.token, { expires: 30 });
          // store.commit('SET_Authorization', res.data.data.token);
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          localStorage.setItem("user_id", res.data.data.user_id);
          // start
          store.dispatch("getDeviceInfo").then(async () => {
            if (store.state.deviceInfo.length > 0) {
              if (store.state.deviceInfo[0].id) {
                store.commit("setCurrentDeviceId", store.state.deviceInfo[0].id);
                store.commit("setCurrentDeviceInfo", store.state.deviceInfo[0]);
                store.commit('setDeviceType', 0);
              } else {
                store.commit('setCurrentAppleId', store.state.deviceInfo[0].apple_id)
                store.commit("setCurrentDeviceId", store.state.deviceInfo[0].devices[0].id);
                store.commit("setCurrentDeviceInfo", { ...store.state.deviceInfo[0].devices[0], is_expired: store.state.deviceInfo[0].is_expired, expire_at: store.state.deviceInfo[0].expired_at });
                store.commit('setDeviceType', 1);
              }
            }

            await store.dispatch("getUserInfo");

            if (!store.state.subscripts.expires_at) {
              store.commit("setDefaultMenu", null);
              gaEvent("Buy_Page", {
                event_category: "Buy",
                event_label: "From_Login",
              });
              router.push({ path: "/addDevice" });
            } else if (
              !store.state.deviceInfo.length &&
              store.state.subscripts.expires_at
            ) {
              store.commit("setDefaultMenu", null);
              router.push({ path: "/guide" });
              store.commit("setCurrentDeviceId", "Device 1");
              store.commit("setCurrentDeviceInfo", {
                name: "leftTab.unboundName",
                id: "Device 1",
                model: "leftTab.unbound",
              });
              store.commit("setBoundImg", true);
            } else {
              router.push({ path: "/home" });
              store.commit("setDefaultMenu", "home");
            }

            // // 调取设备信息
            store.dispatch("updateMenuList");

            setTimeout(() => {
              formData.isGoogleSign = false
              formData.googleRedirect = false
            }, 10000)
          }).catch(() => {
            setTimeout(() => {
              formData.isGoogleSign = false
              formData.googleRedirect = false
            }, 10000)
          });

          ElMessage.success(i18n.t("loginSucceeded"));

          let code = localStorage.getItem("authorization");
          if (localStorage.getItem("authorization")) {
            progressbarWs(code, 1);
          }
          if (localStorage.getItem("oos") == "1") {
            refreshTab();
            localStorage.setItem("oos", "3");
          } else if (localStorage.getItem("oos") == "2") {
            refreshTab();
            localStorage.setItem("oos", "3");
          }
        } else {
          tools.UserEvent("Login_Fail", res.data.code);
          tools.responseUse(res.data.code);
          let loginTimes = Math.round(Date.now() - loginstartTime);
          gaEvent("SignInResult_LoadingTime", {
            event_category: "LoadingTime",
            event_label: `${loginTimes}ms`,
          });
        }
      })
        .catch((err) => {
          console.log("登录失败", err);
          tools.UserEvent("Login_Fail", "None");
          formData.disable = false;
          // formData.btnText = "Sign up";
          formData.isSignUp = true;
          let loginTimes = Math.round(Date.now() - loginstartTime);
          gaEvent("SignInResult_LoadingTime", {
            event_category: "LoadingTime",
            event_label: `${loginTimes}ms`,
          });
        }).finally(() => {
          setTimeout(() => {
            formData.isGoogleSign = false
            formData.googleRedirect = false
          }, 10000)
        })
    }

    let goLogIn = () => {
      router.push({ path: "/login" });
    };

    // V=1.0.0 版本号跟着项目版本号走
    let goTermsOfService = () => {
      if (window.location.origin.includes('parental')) {
        window.open(
          `https://cbs.parentalguard.com/go?pid=7722&a=tc&v=${config.versionValue}`,
          "_blank"
        );
      } else {
        window.open(
          `https://cbs.famikeep.com/go?pid=5530&a=tc&v=${config.versionValue}`,
          "_blank"
        );
      }
    };

    // V=1.0.0 版本号跟着项目版本号走
    let goPrivacyPolicy = () => {
      if (window.location.origin.includes('parental')) {
        window.open(
          `https://cbs.parentalguard.com/go?pid=7722&a=p&v=${config.versionValue}`,
          "_blank"
        );
      } else {
        window.open(
          `https://cbs.famikeep.com/go?pid=5530&a=p&v=${config.versionValue}`,
          "_blank"
        );
      }
    };

    let activeLink = () => {
      formData.isAgree = !formData.isAgree;
      if (!formData.isAgree) {
        formData.isOk = false
      } else if (formData.isAgree && formData.isAdult) {
        formData.isOk = true
      }
    };

    let clickIsAdult = () => {
      formData.isAdult = !formData.isAdult;
      if (!formData.isAdult) {
        formData.isOk = false
      } else if (formData.isAgree && formData.isAdult) {
        formData.isOk = true
      }
    };

    return {
      store,
      emailRule,
      registerFormRef,
      UserEmailTrim,
      formData,
      setRegister,
      goGoole,
      goLogIn,
      goTermsOfService,
      goPrivacyPolicy,
      checkEmail,
      senMsg,
      activeLink,
      clickIsAdult,
      homePage,
      originUrl,
      equipmentType,
    };
  },
});
